import React from 'react';
import HeroSection from '../components/HeroSection';

const ContactUs = () => {
  return (
    <div>
      <HeroSection title="Contact Us" subtitle="Having Queries? Don't hesitate to reach out to us!" />

      <section className="py-8 bg-green-100">
        <div className="container mx-auto px-4 max-w-6xl">
          <div className="flex flex-col md:flex-row items-start justify-center">
            
            <div className="md:w-1/2 bg-white p-6 rounded-lg shadow-md md:mr-8"> 
              <h2 className="text-2xl font-bold mb-4 text-green-800">Enquiry Form</h2>
              <form>
                <div className="mb-4">
                  <label className="block text-gray-700">Name</label>
                  <input type="text" className="w-full px-3 py-2 border rounded" required />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Email</label>
                  <input type="email" className="w-full px-3 py-2 border rounded" required />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Message</label>
                  <textarea className="w-full px-3 py-2 border rounded" rows="4" required></textarea>
                </div>
                
                <div className="mb-4">
                  <div className="g-recaptcha" data-sitekey="YOUR_RECAPTCHA_SITE_KEY"></div>
                </div>
                <button type="submit" className="bg-green-800 text-white py-2 px-4 rounded">
                  Submit
                </button>
              </form>
            </div>

            <div className="md:w-1/2 mt-8 md:mt-0">
              <h2 className="text-2xl font-bold mb-4 text-green-800">Contact Information</h2>
              <p className="text-lg text-gray-700 mb-2">
                <strong>Email:</strong> info@ev91riderz.com
              </p>
              <p className="text-lg text-gray-700 mb-4">
                <strong>Phone:</strong> +91 996 497 5537
              </p>
              
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.588846038764!2d77.6408393153522!3d12.97159899085745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c8b8c8f7%3A0x2b8f8f8f8f8f8f8f!2sNo.17%2C%20RK%20Complex%2C%2017th%20F%20Cross%20Road%2C%20Laksmipuram%2C%20Indiranagar%2C%20Bengaluru%2C%20Karnataka%20560038!5e0!3m2!1sen!2sin!4v1612345678901!5m2!1sen!2sin"
                width="100%"
                height="250"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 bg-white">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4 text-green-800">Our Locations</h2>
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/3 p-4">
              <h3 className="text-xl font-semibold text-green-800">Bengaluru</h3>
              <p className="text-gray-700">
                Address: No.16, RK Complex, 17th F Cross Road, Laksmipuram, Indiranagar, Bengaluru, Karnataka-560038
              </p>
            </div>
            <div className="md:w-1/3 p-4 border-l border-gray-300">
              <h3 className="text-xl font-semibold text-green-800">Chennai</h3>
              <p className="text-gray-700">
                Address: No. 351/270, MKN Road, Alandur, Chennai, <br /> TamilNadu-600016
              </p>
            </div>
            <div className="md:w-1/3 p-4 border-l border-gray-300">
              <h3 className="text-xl font-semibold text-green-800">Hyderabad</h3>
              <p className="text-gray-700">
                Address: No. 1-1-651/A/2, Gandhi Nagar, Secunderabad, Hyderabad, Telangana-500080
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
