import React, { useState } from 'react';
import mapImage from '../assets/map-image.jpeg'

const services = [
  {
    title: 'EV-Based Delivery',
    description: 'Experience swift, reliable delivery with our top-notch service. Your goods, delivered with precision and care wherever you need them.',
  },
  {
    title: '3PL (Third-Party Logistics)',
    description: 'Streamline your supply chain with our reliable third-party logistics solutions. Efficient and scalable logistics tailored for your business.',
  },
  {
    title: 'EV-Rental',
    description: 'Drive the future with our premium EV rentals—eco-friendly and efficient. Rent an electric vehicle today and experience sustainable mobility.',
  },
];


const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeService, setActiveService] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', description: '' });
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const handleOpenModal = (serviceTitle) => {
    setActiveService(serviceTitle);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenForm = () => {
    setShowModal(false);
    setFormData({ name: '', email: '', phone: '', description: '' });
    setErrors({});
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number is invalid';
    }
    if (!formData.description) newErrors.description = 'Description is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      console.log('Form submitted:', formData);
      setShowForm(false);
      setShowModal(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    } else {
      setErrors(validationErrors);
    }
  };

  const getModalContent = (serviceTitle) => {
    switch (serviceTitle) {
      case 'EV-Based Delivery':
        return (
          <>
            <h3 className="text-2xl font-bold mb-4 text-green-800 text-center">EV-Based Delivery</h3>
            <p className="text-gray-700 mb-4">
              Our EV-Based Pickup and Drop service offers a sustainable solution for transporting your products with utmost care and efficiency. Designed to meet the needs of both businesses and individuals, our service ensures timely and secure delivery of your goods.
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Eco-friendly electric vehicles for zero-emission transportation.</li>
              <li>Flexible pickup and drop-off scheduling to suit your needs.</li>
              <li>Real-time tracking and notifications for seamless communication.</li>
              <li>Professional drivers trained to handle your products with care.</li>
              <li>Enhanced safety measures to protect your goods during transit.</li>
            </ul>
            <p className="text-gray-700">
              Our service is ideal for a wide range of applications, from delivering packages and merchandise to transporting sensitive items. Experience the future of logistics with our innovative EV-based solution that combines sustainability with superior service.
            </p>
          </>
        );
      case '3PL (Third-Party Logistics)':
        return (
          <>
            <h3 className="text-2xl font-bold mb-4 text-green-800 text-center">3PL (Third-Party Logistics)</h3>
            <p className="text-gray-700 mb-4">
              Comprehensive Supply Chain Management: We manage every aspect of your supply chain, from warehousing and inventory management to transportation and delivery. Our end-to-end service ensures that all components of your supply chain work together seamlessly.
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Scalable Solutions: Whether you're a small business or a large enterprise, our 3PL services are designed to scale with your operations.</li>
              <li>Advanced Technology: Our logistics solutions are powered by the latest technology.</li>
              <li>Cost Efficiency: By outsourcing your logistics operations to us, you benefit from our expertise and infrastructure.</li>
              <li>Dedicated Support: Our team of logistics professionals provides dedicated support and personalized service.</li>
              <li>Global Reach: Our logistics network spans multiple regions, enabling us to manage international shipments.</li>
            </ul>
          </>
        );
      case 'EV-Rental':
        return (
          <>
            <h3 className="text-2xl font-bold mb-4 text-green-800 text-center">EV-Rental</h3>
            <p className="text-gray-700 mb-4">
              Diverse Fleet: Choose from a variety of electric scooters and motorcycles. Our fleet includes models that cater to different needs.
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Eco-Friendly Transportation: Our electric 2-wheelers produce zero emissions.</li>
              <li>Innovative Technology: Experience the latest in electric mobility technology.</li>
              <li>Convenient Charging Solutions: We provide access to a network of charging stations.</li>
              <li>Comfort and Safety: Enjoy a comfortable ride with features such as ergonomic seating.</li>
              <li>Flexible Rental Options: We offer flexible rental terms to suit your needs.</li>
              <li>Affordable Pricing: Benefit from competitive rental rates with no hidden fees.</li>
            </ul>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
     
      <section className="py-12 bg-green-100">
        <div className="container mx-auto px-4">
          <div className="bg-white p-8 rounded-2xl shadow-xl flex flex-col items-center">
            <div className="text-center">
              <h1 className="text-5xl font-bold mb-4 text-green-800">Our Services</h1>
              <p className="text-lg text-gray-700 mb-4">
                Discover the range of services we offer to enhance your experience in the electric mobility sector. From innovative delivery solutions to efficient logistics and premium vehicle rentals, we are here to meet your needs.
              </p>
              <p className="text-lg text-gray-700">
                Explore our offerings and find out how we can help you achieve your goals with sustainability and efficiency at the core.
              </p>
            </div>
          </div>
        </div>
      </section>

      
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center text-green-800">Our Key Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-center">
            {services.map((service, index) => (
              <div key={index} className="bg-green-50 p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-2 text-green-800">{service.title}</h3>
                <p className="text-gray-700">{service.description}</p>
                <button
                  onClick={() => handleOpenModal(service.title)}
                  className="mt-4 bg-green-800 text-white py-2 px-4 rounded"
                >
                  Learn More
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl mx-4 relative">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-2xl text-gray-600 hover:text-gray-800"
            >
              &times;
            </button>
            {getModalContent(activeService)}
            <div className="flex justify-center">
              <button
                onClick={handleOpenForm}
                className="mt-4 bg-green-800 text-white py-2 px-4 rounded"
              >
                Enquire Now
              </button>
            </div>
          </div>
        </div>
      )}

      
      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-xl mx-4 relative">
            <button
              onClick={handleCloseForm}
              className="absolute top-2 right-2 text-2xl text-gray-600 hover:text-gray-800"
            >
              &times;
            </button>
            <h3 className="text-2xl font-bold mb-4 text-green-800 text-center">Enquiry Form</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.name && <p className="text-red-600">{errors.name}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.email && <p className="text-red-600">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.phone && <p className="text-red-600">{errors.phone}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                  rows="4"
                ></textarea>
                {errors.description && <p className="text-red-600">{errors.description}</p>}
              </div>
              <div className="flex justify-center">
                <button type="submit" className="bg-green-800 text-white py-2 px-4 rounded">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

     
      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-4 text-center">
            <div className="text-green-800 text-4xl mb-4">✔</div>
            <p className="text-lg text-gray-700 mb-4">Thank you for your interest. We will get back to you shortly!</p>
          </div>
        </div>
      )}
      
      <section className="py-8 bg-white">
      <h2 className="text-4xl font-bold mb-8 text-center text-green-800">Our Presence</h2>
        <div className="container mx-auto">
          <img src={mapImage} alt="Map" className="w-1/2 h-auto mx-auto rounded-lg" />
        </div>
      </section>
    </div>
  );
};

export default Services;